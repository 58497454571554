/* purgecss start ignore */
@tailwind base;
@tailwind components;
/* purgecss end ignore */
@tailwind utilities;

html {
  cursor: crosshair;
}

ul a {
  text-decoration: underline;
  font-weight: 700;
}

ul a:hover {
  text-decoration: none;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 7px;
  height: 10px;
  scroll-behavior: smooth;
  cursor: grab !important;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
