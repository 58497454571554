.resumeGrid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	background-color: black;
	border: 1px solid black;
	border-top: 0px;
}
.resumeGrid__item {
	background-color: white;
	border: 1px solid black;
	border-radius: 10px;
}

.resumeGrid__item:nth-child(3) {
	background-color: rgb(217, 222, 228);
}

.resumeGrid__item:nth-child(3):hover {
	background-color: white;
	transition: background-color 0.2s ease-in-out;
}

.resumeGrid__item:nth-child(4) {
    align-items: flex-end;
    font-size: smaller;
	color: rgba(217, 222, 228, 0.4);
}

.resumeGrid__item:nth-child(7) .resumeGrid__item__content .skills {
    display: flex;
    flex-wrap: wrap;
    gap: 3px;
}

.resumeGrid__item__content {
	margin: auto;
	margin: 5vh 5vw 5vh 5vw;
}

.resumeGrid__item__content h2 {
	font-size: 2.5rem;
	font-weight: 700;
	line-height: 1.2;
	margin: 0 0 1.5rem 0;
}

.resumeGrid__item__content h3 {
	font-size: 1.5rem;
	font-weight: 700;
	line-height: 1.2;
	margin: 0 0 1rem 0;
}

.resumeGrid__item__content__education__item {
	margin: 0 0 1.5rem 0;
	border: 1px solid black;
	border-radius: 10px;
	padding: 1rem;
}

.resumeGrid__item__content__education__item:hover {
	background-color: rgb(217, 222, 228);
	transition: background-color 0.2s ease-in-out;
}

.label__inprogress {
	border: 1px solid #000;
	border-radius: 5px;

	padding: 0.2rem 0.5rem;
	font-size: 0.8rem;
	font-weight: 700;
	margin-left: 0.5rem;
	transition: all 0.2s ease-in-out;
}

.label__inprogress:hover {
	border: 1px solid rgba(0, 0, 0, 0.7);
}

.resumeGrid__item__content ul {
	list-style-type: initial;
	margin-left: 1rem;
}

.resumeGrid__item__content__volunteering__item,
.resumeGrid__item__content__awards__item,
.resumeGrid__item__content__certifications__item,
.resumeGrid__item__content__skills__item {
	margin: 0 0 1.5rem 0;
}

/* phone only */
@media (max-width: 599px) {
}
/* tablet portrait and up */
@media (min-width: 600px) {
}

/* less than tablet landscape */
@media (max-width: 899px) {
	.resumeGrid {
		grid-template-columns: 1fr;
		grid-template-rows: auto;
	}

	.resumeGrid__item:nth-child(1) {
		grid-row: 2;
	}

	.resumeGrid__item:nth-child(2) {
		grid-row: 1;
	}

	.resumeGrid__item:nth-child(4) {
		display: none;
	}
}

/* tablet landscape and up */
@media (min-width: 900px) {
	.resumeGrid__item:nth-child(1) {
		grid-column: 1 / 3;
		grid-row: 1 / 3;
	}

	.resumeGrid__item:nth-child(2) {
		grid-column: 3 / 4;
	}

	.resumeGrid__item:nth-child(3) {
		grid-column: 3 / 4;
	}

	.resumeGrid__item:nth-child(4) {
		grid-column: 1;
		grid-row: 3 / 5;
        display: flex;
	}

	.resumeGrid__item:nth-child(7) {
		grid-column: 2 / 4;
	}
}
/* desktop and up */
@media (min-width: 1200px) {
}
/* big desktop and up */
@media (min-width: 1800px) {
}
