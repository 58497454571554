.footer-contain {
    background-color: #f5f5f5;
    padding: 70px;
    padding-left: 150px;
    padding-right: 150px;
    margin-top: 5vh;
    text-align: center;
    /*top border*/
    border-top: 1px solid #e5e5e5;
}

.footer-flex {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: space-around;
    padding: 0.5rem;
}

.footer-flex h3 {
    font-size: large;
}

.footer-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 30px;
    padding: 0.5rem;
}

.footer-button, .footer-button-bolded {
    text-decoration: none;
    font-weight: 700;
    padding: 18px;
}

.footer-button:hover {
    text-decoration: underline;
}

.footer-button-bolded {
    color: white;
    background-color: black;
    border: 3px solid transparent;
}

.footer-button-bolded:hover {
    color: black;
    background-color: transparent;
    border: 3px solid black;
}

/* less than 900px */
@media (max-width: 900px) {
    .footer-contain {
        padding: 10vw;
    }

    .footer-flex {
        flex-direction: column;
        gap: 30px;
        padding: 0px;
    }
}

/*less than 500px*/
@media (max-width: 500px) {
    .footer-buttons {
        flex-direction: column;
        gap: 10px;
        padding: 0.5rem;
    }
}